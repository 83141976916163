<template>
  <b-container fluid class="px-0">
    <header class="mb-4 px-4 py-2 position-relative bg-main text-center">
      <router-link :to="{ name: 'top'}" class="position-center-y text-white" style="left: 1rem;">
        <font-awesome-icon icon="angle-left" size="lg"/>
      </router-link>
      <h3 class="flex-grow-1 text-white font-weight-bold">商品選択</h3>
    </header>

    <section class="px-3">
      <header class="mb-2 app-header border-bottom border-main">
        <h4 class="h4 font-weight-bold">商品選択</h4>
      </header>
      <p class="mb-4">お見積する商品を選択してください。</p>

      <router-link :to="{ name: 'form', params: { productId: 1 } }"
                   class="mb-3 d-block text-center text-decoration-none">
        <b-button block variant="outline-main">1. 無線タテ綴じ冊子（カラー・モノクロ・混在）</b-button>
      </router-link>

      <router-link :to="{ name: 'form', params: { productId: 2 } }"
                   class="mb-3 d-block text-center text-decoration-none">
        <b-button block variant="outline-main">2. 無線ヨコ綴じ冊子（カラー・モノクロ・混在）</b-button>
      </router-link>

      <router-link :to="{ name: 'form', params: { productId: 3 } }"
                   class="mb-3 d-block text-center text-decoration-none">
        <b-button block variant="outline-main">3. 中綴じ冊子（タテ・ヨコ）（1200dpiトナー形式）</b-button>
      </router-link>

      <router-link :to="{ name: 'form', params: { productId: 4 } }"
                   class="mb-3 d-block text-center text-decoration-none">
        <b-button block variant="outline-main">4. ちらし印刷（1200dpiトナー形式）</b-button>
      </router-link>

      <router-link :to="{ name: 'form', params: { productId: 5 } }"
                   class="mb-3 d-block text-center text-decoration-none">
        <b-button block variant="outline-main">5. チケット・ナンバリング・バーコードﾞ・QRコード印刷</b-button>
      </router-link>

      <router-link :to="{ name: 'form', params: { productId: 6 } }"
                   class="mb-3 d-block text-center text-decoration-none">
        <b-button block variant="outline-main">6. 郵便はがき印刷（宛名・ラベル・ナンバリング・バーコード・QRコード）</b-button>
      </router-link>

      <router-link :to="{ name: 'form', params: { productId: 7 } }"
                   class="mb-3 d-block text-center text-decoration-none">
        <b-button block variant="outline-main">7. 挨拶状印刷（洋1・洋2封筒・挨拶状・二つ折り・単カード・宛名・ラベル・ナンバリング・バーコード・QRコード）</b-button>
      </router-link>

      <router-link :to="{ name: 'form', params: { productId: 8 } }"
                   class="mb-3 d-block text-center text-decoration-none">
        <b-button block variant="outline-main">8. 封筒印刷（角0・宛名・ラベル・ナンバリング・バーコード・QRコード）</b-button>
      </router-link>

      <router-link :to="{ name: 'form', params: { productId: 9 } }"
                   class="mb-3 d-block text-center text-decoration-none">
        <b-button block variant="outline-main">9. 封筒印刷（角1・宛名・ラベル・ナンバリング・バーコード・QRコード）</b-button>
      </router-link>

      <router-link :to="{ name: 'form', params: { productId: 10 } }"
                   class="mb-3 d-block text-center text-decoration-none">
        <b-button block variant="outline-main">10. 封筒印刷（角2・角20・宛名・ラベル・ナンバリング・バーコード・QRコード）</b-button>
      </router-link>

      <router-link :to="{ name: 'form', params: { productId: 11 } }"
                   class="mb-3 d-block text-center text-decoration-none">
        <b-button block variant="outline-main">11. 封筒印刷（長3・宛名・ラベル・ナンバリング・バーコード・QRコード）</b-button>
      </router-link>

      <router-link :to="{ name: 'form', params: { productId: 12 } }"
                   class="mb-3 d-block text-center text-decoration-none">
        <b-button block variant="outline-main">12. 封筒印刷　洋0（洋長3・宛名・ラベル・ナンバリング・バーコード・QRコード）</b-button>
      </router-link>

      <router-link :to="{ name: 'form', params: { productId: 13 } }"
                   class="mb-3 d-block text-center text-decoration-none">
        <b-button block variant="outline-main">13. クリアファイル印刷</b-button>
      </router-link>

    </section>

  </b-container>
</template>

<script>
import {mapWaitingActions, mapWaitingGetters} from 'vue-wait'

export default {

  async beforeRouteLeave (to, from, next) {
    if(to.name === "form"){
      await this.resetFormAction({})
      await this.generateCartAction(parseInt(to.params.productId))

    }
    next()
  },
  mounted() {
    window.parent.postMessage(document.getElementById("app").offsetHeight, '*')
  },
  methods:{
    ...mapWaitingActions("form", [
      "resetFormAction",
      "generateCartAction",
    ]),
  }

}
</script>
