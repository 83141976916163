<template>
  <b-container fluid class="px-0">
    <header class="mb-4 px-4 py-2 bg-main text-center">
      <h3 class="text-white font-weight-bold">オンデマンド印刷冊子クニムネ<br class="d-inline d-md-none"><span
        class="d-none d-md-inline">&nbsp;</span>リアルタイム見積</h3>
    </header>

    <section class="px-3">
      <header class="mb-2 app-header border-bottom border-main">
        <h4 class="h4 font-weight-bold">最先端・高精細・大型トナー形式オンデマンド印刷・製本</h4>
        <h5 class="h5 text-muted">機内印刷＋中綴じ同時進行・無線綴じ製本</h5>
      </header>

      <ul class="mb-5 list-unstyled">
        <li class="li-star">超特急無線綴じ冊子・フルカラー・当日入稿→当日出荷OK。１冊からOK</li>
        <li class="li-star">カラー・モノクロ・白ページ混在、飛び飛びページも1ページ単位でカウント。</li>
      </ul>

      <router-link :to="{ name: 'products' }"
                   class="row px-4 text-center text-decoration-none animate__animated animate__pulse animate__slow animate__infinite">
        <b-button size="lg" variant="outline-main" block class="mx-auto col-9 col-md-6" style="padding: 2rem 0;">秒速即答!<br class="d-inline d-md-none"><span class="d-none d-md-inline">&nbsp;</span>リアルタイムオンライン<br>全自動見積りスタート
        </b-button>
      </router-link>

    </section>

  </b-container>
</template>

<script>
export default {
  mounted() {
    window.parent.postMessage(document.getElementById("app").offsetHeight, '*')
  }
}
</script>
