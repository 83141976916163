//import "../../sass/site/app.scss"

import Vue from 'vue'
import VueWait from 'vue-wait'

import './loads'

import Root from './Root'
import router from 'vueRouter/router'
import store from 'vuex/store'

//console.log(process.env.MIX_SITE_API_HOST)

Vue.mixin({
  methods: {
    idx: idx,
    collect: collect,

    numberFormat: (value)=>{
      const formatter = new Intl.NumberFormat('ja-JP')
      return formatter.format(value)
    },
  },
})

document.addEventListener("DOMContentLoaded", (event)=> {
  const app = new Vue({
    render: h => h(Root),
    router,
    store,
    wait: new VueWait({
      useVuex: false,
      vuexModuleName: 'wait',
    }),
  }).$mount('#app')
})

