<template>
  <b-container fluid class="px-0">
    <header class="mb-4 px-4 py-2 bg-main text-center">
      <h3 class="text-white font-weight-bold">見積依頼完了</h3>
    </header>

    <section class="px-3">

      <header class="mb-2 app-header border-bottom border-main">
        <h4 class="h4 font-weight-bold">見積依頼完了</h4>
      </header>

      <p class="mb-4">
        ご注文頂きありがとうございます。<br>
        ご入力頂いた内容にて、お申し込みが完了いたしました。<br>
        ご注文内容の確認はご入力いただいたご依頼主様のメールアドレスにお送りしております。<br>
        1日以上たっても確認用のメールが届かない場合は、ネットワークに問題があるか、ご入力頂いたメールアドレスが間違っている可能性がありますので、
        お手数ですが本サイト上部に記載の弊社電話番号またはメールアドレスまでお問い合わせ頂けますようお願い致します。<br>
        それでは、お取引のほどどうぞよろしくお願い致します。
      </p>
    </section>

  </b-container>
</template>

<script>
export default {
  mounted() {
    window.parent.postMessage(document.getElementById("app").offsetHeight, '*')
  }
}
</script>
