import isBlank from "underscore.string/isBlank"
import merge from 'lodash/merge';
import FormClient from 'helpers/FormClient';
import {Errors} from 'form-backend-validation'
import {getField, updateField} from 'vuex-map-fields';

const form = {
  product_id: null, //　商品ID

  number_of_prints: 0, // 印刷部数

  print_size_id: null, //　印刷サイズ種別ID

  proofreading_type_id: null, // 校正の有無種別ID
  proofreading_type_number_of_times: 0, // 校正の希望回数
  proofreading_type_number_of_prints: 0, // 校正の希望部数
  proofreading_type_number_of_places: 0, // 校正の出荷先数
  pdf_proofreading_type_id: null,

  spare_type_id: null, // 予備の有無種別ID
  spare_type_number_of_prints: 0, // 予備の希望部数

  front_cover_submitted_type_id: null, // 表紙データ入稿形式種別ID
  front_cover_paper_type_id: null,

  // 挨拶状・洋1封筒
  front_cover_paper_1_type_id: null,
  front_cover_paper_2_type_id: null,
  front_cover_paper_3_type_id: null,
  front_cover_paper_4_type_id: null,
  front_cover_paper_5_type_id: null,
  front_cover_paper_6_type_id: null,
  front_cover_print_color_1_1_type_id: null,
  front_cover_print_color_1_2_type_id: null,
  front_cover_print_color_2_1_type_id: null,
  front_cover_print_color_2_2_type_id: null,

  front_cover_print_color_type_id: null,

  front_cover_print_option_type_id: null,
  front_cover_print_option_type_number_of_die_cuts: 0,
  front_cover_processing_type_id: null,

  //郵便はがき
  front_cover_print_color_with_type_id: "4面付き",
  front_cover_print_color_with_4_type_id: null,
  front_cover_print_option_with_4_type_id: null,
  front_cover_print_color_with_1_type_id: null,
  front_cover_print_option_with_1_type_id: null,

  cover_band_paper_type_id: null,
  cover_band_print_color_type_id: null,
  cover_band_processing_type_id: null,

  body_submitted_type_id: null,
  body_submitted_type_number_of_pages: 0,
  body_paper_type_id: null,

  body_number_of_color_pages: 0,
  body_number_of_black_and_white_pages: 0,
  body_number_of_2_color_pages: 0,
  body_number_of_white_color_pages: 0,
  body_number_of_single_sided_printing_pages: 0,

  title_paper_type_id: null,
  title_paper_type_number_of_pages: 0,
  title_print_color_type_id: null,
  title_print_color_type_number_of_pages: 0,

  binder_paper_type_id: null, // バインダー用紙種別
  binder_print_color_type_id: null, // バインダー印刷色種別

  tri_fold_paper_type_id: null, // 三つ折り
  tri_fold_paper_type_number_of_pages: 0,
  tri_fold_print_color_type_id: null,
  tri_fold_print_color_type_number_of_pages: 0,

  variable_data_print_type_inputs: [],
  variable_data_print_place_inputs: [],

  end_paper_type_id: null,

  binding_method_type_inputs: [],
}

const master = {
  product: {},
  print_sizes: [], // 印刷サイズ種別

  proofreading_types: [], // 校正の有無種別
  pdf_proofreading_types: [], // PDF校正種別

  spare_types: [], // 予備の有無種別

  front_cover_submitted_types: [], // 表紙データ入稿形式種別
  front_cover_paper_types: [], // 表紙用紙種別
  front_cover_print_color_types: [], // 表紙印刷色種別
  front_cover_print_option_types: [], // 表紙印刷オプション種別

  front_cover_paper_1_types: [],
  front_cover_paper_2_types: [],
  front_cover_paper_3_types: [],
  front_cover_paper_4_types: [],
  front_cover_paper_5_types: [],
  front_cover_paper_6_types: [],

  front_cover_print_color_1_1_types: [],
  front_cover_print_color_1_2_types: [],
  front_cover_print_color_2_1_types: [],
  front_cover_print_color_2_2_types: [],

  front_cover_print_color_with_types: [
    {id: "4面付き", name: "4面付き"},
    {id: "一枚もの", name: "一枚もの"},
  ],

  front_cover_print_color_with_4_types: [],
  front_cover_print_option_with_4_types: [],
  front_cover_print_color_with_1_types: [],
  front_cover_print_option_with_1_types: [],

  front_cover_processing_types: [], // 表紙加工種別

  cover_band_paper_types: [], // カバー・帯用紙種別
  cover_band_print_color_types: [], // カバー・帯印刷色種別
  cover_band_processing_types: [], // カバー・帯加工種別

  body_submitted_types: [], //  本文データ入稿形式種別
  body_paper_types: [], // 本文用紙種別

  title_paper_types: [], // 扉用紙種別
  title_print_color_types: [], // 扉印刷色種別

  binder_paper_types: [], // バインダー用紙種別
  binder_print_color_types: [], // バインダー印刷色種別

  tri_fold_paper_types: [], // 三つ折り用紙種別
  tri_fold_print_color_types: [], // 三つ折り印刷色種別

  variable_data_print_types: [], // バリアブル印刷データ作成・最適化種別
  variable_data_print_place_types: [], // バリアブル印刷箇所 種別

  end_paper_types: [], // 見返し用紙種別

  binding_method_types: [], // 製本方法種別

  //注文情報
  prefectures: [],
  payment_types: [],
}

const order = {
  project_name: null,
  customer_name: null,
  staff_name: null,

  customer_zip_code: null,
  customer_prefecture_id: null,
  customer_address_1: null,
  customer_address_2: null,
  customer_address_3: null,
  email: null,
  email_confirmation: null,
  phone_number: null,
  fax_number: null,

  payment_type_id: 1,
  desired_delivery_date: null,

  is_enabled_delivery_address: false,

  delivery_zip_code: null,
  delivery_prefecture_id: null,
  delivery_address_1: null,
  delivery_address_2: null,
  delivery_address_3: null,

  note: null,

  // project_name: "テスト案件",
  // customer_name: "テスト太郎",
  // staff_name: null,
  //
  // customer_zip_code: "500-1111",
  // customer_prefecture_id: 1,
  // customer_address_1: "テスト市",
  // customer_address_2: "1-1-1",
  // customer_address_3: null,
  // email: "test@example.com",
  // email_confirmation: "test@example.com",
  // phone_number: "111-2222-3333",
  // fax_number: null,
  //
  // payment_type_id: 1,
  // desired_delivery_date: "2020-08-30",
  //
  // is_enabled_delivery_address: true,
  //
  // delivery_zip_code: "500-2222",
  // delivery_prefecture_id: 2,
  // delivery_address_1: "テスト市",
  // delivery_address_2: "2-2-2",
  // delivery_address_3: null,
  //
  // note: "テスト\nアイウエオ",
}

export default {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',

  state: {
    master,
    defaultMaster: merge({}, master),

    form,
    defaultForm: merge({}, form),

    order,
    defaultOrder: merge({}, order),
    orderErrors: new Errors(),

    aggregate: {
      // クリアファイル
      print_size_price: 0,

      front_cover_submitted_type_price: 0, // 表紙　データ料金
      front_cover_paper_type_price: 0, // 表紙　用紙料金
      front_cover_print_color_type_price: 0, // 表紙　印刷料金
      front_cover_print_option_type_price: 0, // 表紙　印刷オプション料金
      front_cover_processing_type_price: 0, // 表紙　PP加工料金

      front_cover_print_color_type_1_price: 0,
      front_cover_print_color_type_2_price: 0,

      front_cover_print_color_type_with_4_price:0,
      front_cover_print_option_type_with_4_price:0,
      front_cover_print_color_type_with_1_price:0,
      front_cover_print_option_type_with_1_price:0,

      cover_band_paper_type_price: 0, // カバー　用紙料金
      cover_band_print_color_type_price: 0, // カバー　印刷料金
      cover_band_processing_type_price: 0, // カバー　PP加工料金

      body_submitted_type_price: 0, // 本文データ料金
      body_paper_type_price: 0, // 本文用紙料金

      body_number_of_color_pages_price: 0, // 本文データ料金
      body_number_of_black_and_white_pages_price: 0, // 本文モノクロページ印刷料金
      body_number_of_2_color_pages_price: 0, // 本文2色ページ印刷料金
      body_number_of_white_color_pages_price: 0, // 本文白ページ印刷料金
      body_number_of_single_sided_printing_pages_price: 0, // オール片面印刷の裏白ページ

      title_paper_type_price: 0, // 扉　用紙料金
      title_print_color_type_price: 0, // 扉　印刷料金

      binder_paper_type_price: 0, // 耳付きインデックス用紙料金
      binder_print_color_type_price: 0, // 耳付きインデックス印刷料金

      tri_fold_paper_type_price: 0, // 三つ折り　用紙料金
      tri_fold_print_color_type_price: 0, // 三つ折り　印刷料金

      variable_data_print_type_price: 0, // バリアブル（ナンバリング）データ作成・最適化料金
      variable_data_print_place_price: 0, // バリアブル（ナンバリング）印刷料金

      end_paper_type_price: 0, // 見返し用紙料金

      binding_method_type_1_price: 0,
      binding_method_type_2_price: 0,
      binding_method_type_3_price: 0,
      binding_method_type_4_price: 0,
      binding_method_type_5_price: 0,
      binding_method_type_6_price: 0,
      binding_method_type_7_price: 0,
      binding_method_type_8_price: 0,
      binding_method_type_9_price: 0,
      binding_method_type_10_price: 0,
      binding_method_type_11_price: 0,
      binding_method_type_12_price: 0,
      binding_method_type_13_price: 0,
      binding_method_type_14_price: 0,
      binding_method_type_15_price: 0,
      binding_method_type_16_price: 0,
      binding_method_type_17_price: 0,
      binding_method_type_18_price: 0,
      binding_method_type_19_price: 0,
      binding_method_type_20_price: 0,
      binding_method_type_21_price: 0,
      binding_method_type_22_price: 0,
      binding_method_type_23_price: 0,
      binding_method_type_24_price: 0,
      binding_method_type_25_price: 0,
      binding_method_type_26_price: 0,
      binding_method_type_27_price: 0,
      binding_method_type_28_price: 0,

      body_number_of_total_pages: 0, // 本文ページ数合計
      number_of_total_pages: 0, // 総ページ数

      product_sub_total_price: 0, // 税別合計金額
      shipping_price: 0, // 送料
      product_and_shipping_sub_total_price: 0, // 送料込合計金額

      proofreading_type_number_of_times_price: 0, // 校正 回数
      proofreading_type_number_of_prints_price: 0, // 校正　部数
      proofreading_type_number_of_places_price: 0, // 校正　出荷箇所数

      spare_type_price: 0, // 予備　部数
      product_and_shipping_and_proofreading_and_spare_sub_total_price: 0, // 有料校正・予備込料金

      tax: 0, // 税
      total_price: 0, // 税・送料込総合計

      price_per_page: 0, // １ページあたり単価
      price_per_print: 0, // １部あたり単価

      delivery_required_days: 0, // 目安納期
      total_number_of_body_pages: 0, // 本文ページ数合計
      total_number_of_all_pages: 0, // 総ページ数
    },
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
  },
  actions: {
    async generateCartAction({commit}, productId) {
      commit('updateField', {path: 'form.product_id', value: productId});
      const res = await (new FormClient).post(route('site.api.step-form.cart.products.generate', {productId}).url())
      //const res = await (new FormClient).post(`${process.env.MIX_SITE_API_HOST}/step-form/cart/products/${productId}`)
    },
    async setupFormAction({commit, state}, {}) {

      commit('updateField', {path: 'form', value: merge({}, state.defaultForm || {})})
      commit('updateField', {path: 'order', value: merge({}, state.defaultOrder || {})})
      commit('updateField', {path: 'master', value: merge({}, state.defaultMaster || {})})
      commit('updateField', {path: 'orderErrors', value: new Errors()})

      const [mastersRes, inputsRes, aggregatesRes] = await Promise.all([
        (new FormClient).get(route('site.api.step-form.cart.masters.index').url()),
        (new FormClient).get(route('site.api.step-form.cart.inputs.index').url()),
        (new FormClient).get(route('site.api.step-form.cart.aggregates').url()),
      ]);

      ((res) => {
        let k
        commit('updateField', {
          path: `master.${k = 'product'}`,
          value: res[k] || []
        })

        //commit('updateField', {path: k = 'print_sizes', value: res[k] || []});
        commit('updateField', {
          path: `master.${k = 'print_sizes'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })

        commit('updateField', {
          path: `master.${k = 'proofreading_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })
        commit('updateField', {
          path: `master.${k = 'pdf_proofreading_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })


        commit('updateField', {
          path: `master.${k = 'spare_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })

        commit('updateField', {
          path: `master.${k = 'front_cover_submitted_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })
        commit('updateField', {
          path: `master.${k = 'front_cover_paper_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })
        commit('updateField', {
          path: `master.${k = 'front_cover_print_color_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })
        commit('updateField', {
          path: `master.${k = 'front_cover_print_option_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })

        //
        commit('updateField', {
          path: `master.${k = 'front_cover_paper_1_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })
        commit('updateField', {
          path: `master.${k = 'front_cover_paper_2_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })
        commit('updateField', {
          path: `master.${k = 'front_cover_paper_3_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })
        commit('updateField', {
          path: `master.${k = 'front_cover_paper_4_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })
        commit('updateField', {
          path: `master.${k = 'front_cover_paper_5_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })
        commit('updateField', {
          path: `master.${k = 'front_cover_paper_6_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })
        commit('updateField', {
          path: `master.${k = 'front_cover_print_color_1_1_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })
        commit('updateField', {
          path: `master.${k = 'front_cover_print_color_1_2_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })
        commit('updateField', {
          path: `master.${k = 'front_cover_print_color_2_1_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })
        commit('updateField', {
          path: `master.${k = 'front_cover_print_color_2_2_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })

        //郵便葉書
        commit('updateField', {
          path: `master.${k = 'front_cover_print_color_with_4_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })
        commit('updateField', {
          path: `master.${k = 'front_cover_print_option_with_4_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })
        commit('updateField', {
          path: `master.${k = 'front_cover_print_color_with_1_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })
        commit('updateField', {
          path: `master.${k = 'front_cover_print_option_with_1_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })


        commit('updateField', {
          path: `master.${k = 'front_cover_processing_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })

        commit('updateField', {
          path: `master.${k = 'cover_band_paper_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })
        commit('updateField', {
          path: `master.${k = 'cover_band_print_color_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })
        commit('updateField', {
          path: `master.${k = 'cover_band_processing_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })

        commit('updateField', {
          path: `master.${k = 'body_submitted_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })
        commit('updateField', {
          path: `master.${k = 'body_paper_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })

        commit('updateField', {
          path: `master.${k = 'title_paper_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })
        commit('updateField', {
          path: `master.${k = 'title_print_color_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })

        commit('updateField', {
          path: `master.${k = 'binder_paper_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })
        commit('updateField', {
          path: `master.${k = 'binder_print_color_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })

        commit('updateField', {
          path: `master.${k = 'tri_fold_paper_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })
        commit('updateField', {
          path: `master.${k = 'tri_fold_print_color_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })

        commit('updateField', {
          path: `master.${k = 'variable_data_print_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })
        commit('updateField', {
          path: `master.${k = 'variable_data_print_place_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })

        commit('updateField', {
          path: `master.${k = 'end_paper_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })
        commit('updateField', {
          path: `master.${k = 'binding_method_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })

        commit('updateField', {
          path: `master.${k = 'prefectures'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })
        commit('updateField', {
          path: `master.${k = 'payment_types'}`,
          value: collect(res[k] || []).reject((item) => isBlank(item.id)).all()
        })

      })(mastersRes);

      ((res) => {
        collect(res).each((value, key) => commit('updateField', {path: `form.${key}`, value: value}))
      })(inputsRes);

      ((res) => {
        collect(res).each((value, key) => commit('updateField', {path: `aggregate.${key}`, value: value}))
      })(aggregatesRes);

    },
    async resetFormAction({state, commit}) {
      commit('updateField', {path: 'form', value: merge({}, state.defaultForm || {})})
      commit('updateField', {path: 'order', value: merge({}, state.defaultOrder || {})})
      commit('updateField', {path: 'master', value: merge({}, state.defaultMaster || {})})
      commit('updateField', {path: 'orderErrors', value: new Errors()})
    },

    async updateServerInputAction({state, commit}, inputs) {
      const res = await (new FormClient)
        .withData(inputs)
        .patch(route('site.api.step-form.cart.inputs.update', {}).url())
    },
    async reloadAggregatesAction({state, commit}, {}) {
      await (async () => {
        const res = await (new FormClient).get(route('site.api.step-form.cart.aggregates').url())
        collect(res).each((value, key) => commit('updateField', {path: `aggregate.${key}`, value: value}))
      })()
    },
    async submitOrderAction({state, commit}, {}) {
      await (async () => {
        const form = new FormClient
        const res = await form.withData(state.order).post(route('site.api.step-form.order.store').url())
          .catch((err) => {
            commit('updateField', {
              path: 'orderErrors',
              value: form.errors || {}
            })
            throw  err
          })
      })()
    }
  }
}
