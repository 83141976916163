<template>
  <section>

    <!-- 印刷仕様 -->
    <section class="mb-4">

      <header class="mb-2 app-header border-bottom border-main">
        <h4 class="h4 font-weight-bold">印刷仕様</h4>
      </header>
      <p class="mb-4">印刷仕様を入力してください</p>

      <!-- 部数 -->
      <b-form-group
        label-cols-md="3"
        label="部数"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <b-input-group append="部">
          <b-form-input type="number" placeholder="部数を入力" number :debounce="1000" v-model="number_of_prints"
                        class="col-md-3" min="0" required/>
        </b-input-group>
      </b-form-group>

      <!-- 印刷サイズ -->
      <b-form-group
        label-cols-md="3"
        label="印刷サイズ"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <b-form-select v-model="print_size_id" :options="print_sizes" value-field="id" text-field="name"
                       class="col-md-6">
          <template v-slot:first>
            <b-form-select-option :value="null" disabled>選択してください</b-form-select-option>
          </template>
        </b-form-select>
        <b-form-text>
          ※ カスタムの場合は、上記各サイズ以内の近い項目を選択し、備考にカスタムサイズを記入してください。
        </b-form-text>

      </b-form-group>

      <h5 class="h5 app-sub-header mb-2">校正の有無</h5>

      <!-- 校正の有無 -->
      <b-form-group
        label-cols-md="3"
        label="校正の有無"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <b-form-select v-model="proofreading_type_id" :options="proofreading_types" value-field="id" text-field="name"
                       class="col-md-6">
          <template v-slot:first>
            <b-form-select-option :value="null" disabled>選択してください</b-form-select-option>
          </template>
        </b-form-select>
        <b-form-text>
          <div>※ 印刷後、その印刷物スキャンのPDF校正のみになります。</div>
        </b-form-text>
      </b-form-group>

      <template v-if="proofreading_type_id === 'あり'">
        <b-form-group
          label-cols-md="3"
          label="校正の希望回数"
          label-class="text-md-right font-weight-bold pr-3"
        >
          <b-input-group append="回">
            <b-form-input type="number" placeholder="" number :debounce="1000"
                          v-model="proofreading_type_number_of_times"
                          class="col-md-3" min="0"/>
          </b-input-group>
          <b-form-text>
            ※ 1回まで無料
          </b-form-text>
        </b-form-group>

        <b-form-group
          label-cols-md="3"
          label="校正の希望部数"
          label-class="text-md-right font-weight-bold pr-3"
        >
          <b-input-group append="部">
            <b-form-input type="number" placeholder="" number :debounce="1000"
                          v-model="proofreading_type_number_of_prints"
                          class="col-md-3" min="0"/>
          </b-input-group>
          <b-form-text>
            ※ 1部まで無料
          </b-form-text>
        </b-form-group>

        <b-form-group
          label-cols-md="3"
          label="校正の出荷先数"
          label-class="text-md-right font-weight-bold pr-3"
        >
          <b-input-group append="箇所">
            <b-form-input type="number" placeholder="" number :debounce="1000"
                          v-model="proofreading_type_number_of_places"
                          class="col-md-3" min="0"/>
          </b-input-group>
          <b-form-text>
            ※ 1箇所まで無料(AM入稿→翌日郵便受投函）
          </b-form-text>

        </b-form-group>

        <!-- 校正の有無 -->
        <b-form-group
          label-cols-md="3"
          label="印刷後、その印刷物のPDF校正"
          label-class="text-md-right font-weight-bold pr-3"
        >
          <b-form-select v-model="pdf_proofreading_type_id" :options="pdf_proofreading_types" value-field="id"
                         text-field="name"
                         class="col-md-6">
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>選択してください</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>


      </template>

      <h5 class="h5 app-sub-header mb-2">予備の有無</h5>

      <!-- 予備の有無 -->
      <b-form-group
        label-cols-md="3"
        label="予備の有無"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <b-form-select v-model="spare_type_id" :options="spare_types" value-field="id" text-field="name"
                       class="col-md-6">
          <template v-slot:first>
            <b-form-select-option :value="null" disabled>選択してください</b-form-select-option>
          </template>
        </b-form-select>

      </b-form-group>

      <template v-if="spare_type_id === 'あり'">
        <b-form-group
          label-cols-md="3"
          label="予備の希望部数"
          label-class="text-md-right font-weight-bold pr-3"
        >
          <b-input-group append="部">
            <b-form-input type="number" placeholder="" number :debounce="1000" v-model="spare_type_number_of_prints"
                          class="col-md-3" min="0"/>
          </b-input-group>
          <b-form-text>
            ※ 21部～99部まで1部、100部～499部まで2部、500部～999部まで3部、1000部～4部まで予備部数無料<br>
            ※上記を超える場合、予備分も注文部数にプラスして注文して下さい。　
          </b-form-text>
        </b-form-group>
      </template>

    </section>

    <!-- 印刷物仕様 -->
    <section class="mb-4">
      <header class="mb-2 app-header border-bottom border-main">
        <h4 class="h4 font-weight-bold">印刷物仕様</h4>
      </header>
      <p class="mb-4">印刷物仕様を入力してください</p>

      <b-form-group
        label-cols-md="3"
        label="データ入稿形式"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <b-form-select v-model="front_cover_submitted_type_id" :options="front_cover_submitted_types" value-field="id"
                       text-field="name"
                       class="col-md-6">
          <template v-slot:first>
            <b-form-select-option :value="null" disabled>選択してください</b-form-select-option>
          </template>
        </b-form-select>

      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="用紙"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <b-form-select v-model="front_cover_paper_type_id" :options="front_cover_paper_types" value-field="id"
                       text-field="name"
                       class="col-md-6" :disabled="front_cover_submitted_type_id === 'なし'">
          <template v-slot:first>
            <b-form-select-option :value="null" disabled>選択してください</b-form-select-option>
          </template>
        </b-form-select>

      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="印刷色"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <b-form-select v-model="front_cover_print_color_type_id" :options="front_cover_print_color_types"
                       value-field="id" text-field="name"
                       class="col-md-6" :disabled="front_cover_submitted_type_id === 'なし'">
          <template v-slot:first>
            <b-form-select-option :value="null" disabled>選択してください</b-form-select-option>
          </template>
        </b-form-select>

      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="印刷オプション"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <b-form-select v-model="front_cover_print_option_type_id" :options="front_cover_print_option_types"
                       value-field="id" text-field="name"
                       class="col-md-6" :disabled="front_cover_submitted_type_id === 'なし'">
          <template v-slot:first>
            <b-form-select-option :value="null" disabled>選択してください</b-form-select-option>
          </template>
        </b-form-select>

      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="パウチ・ラミネート・ＰＰ加工"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <b-form-select v-model="front_cover_processing_type_id" :options="front_cover_processing_types" value-field="id"
                       text-field="name"
                       class="col-md-6" :disabled="front_cover_submitted_type_id === 'なし'">
          <template v-slot:first>
            <b-form-select-option :value="null" disabled>選択してください</b-form-select-option>
          </template>
        </b-form-select>

      </b-form-group>

    </section>

    <!-- バリアブルデータ仕様 -->
    <section class="mb-4">
      <header class="mb-2 app-header border-bottom border-main">
        <h4 class="h4 font-weight-bold">バリアブルデータ仕様</h4>
      </header>
      <p class="mb-4">バリアブルデータ仕様を入力してください</p>

      <h5 class="mb-1 h5 app-sub-header mb-2">バリアブル　データ作成・最適化の選択（データと印刷はセット）</h5>


      <b-card class="mb-2" v-for="(variable_data_print_type_input,index) in variable_data_print_type_inputs"
              :key="`variable_data_print_type_inputs_${index}`">

        <b-form-group
          label-cols-md="3"
          :label="`バリアブル印刷データ作成・最適化(${index+1})`"
          label-class="text-md-right font-weight-bold pr-3"
        >
          <b-form-select :options="variable_data_print_types" value-field="id"
                         v-model="variable_data_print_type_input.id"
                         text-field="name"
                         class="col-md-6">
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>選択してください</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>

        <template v-if="variable_data_print_place_inputs[index]">

          <b-form-group
            label-cols-md="3"
            :label="`バリアブル印刷箇所(${index+1})`"
            label-class="text-md-right font-weight-bold pr-3"
          >

            <b-form-select v-model="variable_data_print_place_inputs[index].id"
                           :options="variable_data_print_place_types"
                           value-field="id" text-field="name"
                           class="col-md-6">
              <template v-slot:first>
                <b-form-select-option :value="null" disabled>選択してください</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group
            v-if="variable_data_print_place_inputs[index].id !== 'なし'"
            label-cols-md="3"
            :label="`バリアブル印刷箇所数(${index+1})`"
            label-class="text-md-right font-weight-bold pr-3"
          >
            <b-input-group append="箇所">
              <b-form-input type="number" placeholder="" number :debounce="1000"
                            v-model="variable_data_print_place_inputs[index].value"
                            class="col-md-3" min="0"/>
            </b-input-group>
          </b-form-group>

        </template>

        <b-row v-if="index !== 0" no-gutters>
          <b-col offset-md="3">
            <button type="button" class="btn btn-secondary" @click="removeVariableDataPrintTypeInput(index)">削除</button>
          </b-col>
        </b-row>

      </b-card>

      <b-row>
        <b-col offset-md="3">
          <button type="button" class="btn btn-success" @click="addVariableDataPrintTypeInput"
                  :disabled="isDisabledAddVariableDataPrintTypeInput">追加
          </button>
        </b-col>
      </b-row>

    </section>

    <!-- 製本方法仕様 -->
    <section class="mb-4">

      <header class="mb-2 app-header border-bottom border-main">
        <h4 class="h4 font-weight-bold">製本方法仕様</h4>
      </header>
      <p class="mb-4">製本方法仕様を入力してください</p>

      <b-card class="mb-2" v-for="(binding_method_type_input,index) in binding_method_type_inputs"
              :key="`binding_method_type_input_${index}`">
        <b-form-group
          label-cols-md="3"
          :label="index === 0? '製本方法' : `製本方法(オプション${index})`"
          label-class=" text-md-right font-weight-bold pr-3
        "
        >
          <b-form-select v-model="binding_method_type_input.id" :options="binding_method_types"
                         value-field="id" text-field="name"
                         class="col-md-6">
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>選択してください</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>

        <template v-if="isExistenceBindingMethodTypeValue(binding_method_type_input.id)">
          <b-form-group
            label-cols-md="3"
            :label="index === 0? '製本方法加工数' : `製本方法加工数(オプション${index})`"
            label-class="text-md-right font-weight-bold pr-3"
          >
            <b-input-group append="箇所">
              <b-form-input type="number" placeholder="" number :debounce="1000"
                            v-model="binding_method_type_input.value"
                            class="col-md-3" min="0"/>
            </b-input-group>
          </b-form-group>
        </template>

        <b-row v-if="index !== 0" no-gutters>
          <b-col offset-md="3">
            <button type="button" class="btn btn-secondary" @click="removeBindingMethodTypeInput(index)">削除
            </button>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col offset-md="3">
          <button type="button" class="btn btn-success" @click="addBindingMethodTypeInput"
                  :disabled="isDisabledAddBindingMethodTypeInput">追加
          </button>
        </b-col>
      </b-row>

    </section>

    <div class="my-5">
      <hr>
    </div>

    <!-- 見積もり金額 -->
    <section class="mb-4">
      <header class="mb-2 app-header border-bottom border-main">
        <h4 class="h4 font-weight-bold">見積もり金額</h4>
      </header>

      <h5 class="h5 app-sub-header mb-2">印刷仕様</h5>

      <b-form-group
        label-cols-md="3"
        label="部数"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ number_of_prints }}<small class="ml-1">部</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="印刷サイズ"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{
            idx(collect(print_sizes), (_) => _.firstWhere('id', print_size_id).name)
          }}</p>
      </b-form-group>

      <h5 class="h5 app-sub-header mb-2">印刷物仕様</h5>

      <b-form-group
        label-cols-md="3"
        label="データ料金"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(front_cover_submitted_type_price) }}<small
          class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="用紙料金"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(front_cover_paper_type_price) }}<small
          class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="印刷料金"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(front_cover_print_color_type_price) }}<small
          class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="印刷オプション料金"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(front_cover_print_option_type_price) }}<small
          class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="PP・パウチ加工料金"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(front_cover_processing_type_price) }}<small
          class="ml-1">円</small></p>
      </b-form-group>

      <h5 class="h5 app-sub-header mb-2">バリアブルデータ仕様</h5>

      <b-form-group
        label-cols-md="3"
        label="バリアブル（ナンバリング）データ作成・最適化料金"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(variable_data_print_type_price) }}<small
          class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="バリアブル（ナンバリング）印刷料金"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(variable_data_print_place_price) }}<small
          class="ml-1">円</small></p>
      </b-form-group>

      <h5 class="h5 app-sub-header mb-2">製本方法仕様</h5>

      <template v-for="(binding_method_type,index) in binding_method_types" v-if="binding_method_type.id !== 'なし'">

        <b-form-group
          label-cols-md="3"
          :label="binding_method_type.id"
          label-class="text-md-right font-weight-bold pr-3"
          v-if="_self[`binding_method_type_${binding_method_type.no}_price`] > 0"
        >
          <p class="form-control-plaintext">
            {{ numberFormat(_self[`binding_method_type_${binding_method_type.no}_price`]) }}<small
            class="ml-1">円</small></p>
        </b-form-group>
      </template>

      <h5 class="h5 app-sub-header mb-2">合計</h5>

      <b-form-group
        label-cols-md="3"
        label="税別合計金額"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(product_sub_total_price) }}<small class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="送料"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(shipping_price) }}<small class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="送料込合計料金"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(product_and_shipping_sub_total_price) }}<small
          class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="校正回数料金"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(proofreading_type_number_of_times_price) }}<small
          class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="校正部数料金"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(proofreading_type_number_of_prints_price) }}<small
          class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="校正出荷箇所数料金"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(proofreading_type_number_of_places_price) }}<small
          class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="予備部数料金"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(spare_type_price) }}<small class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="有料校正・予備込料金"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">
          {{ numberFormat(product_and_shipping_and_proofreading_and_spare_sub_total_price) }}<small
          class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="税"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(tax) }}<small class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <template #label>
          税・送料込総合計
        </template>
        <p class="form-control-plaintext">{{ numberFormat(total_price) }}<small class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="目安納期"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext"><small class="mr-1">校了後中</small>{{ delivery_required_days }}<small
          class="ml-1">営業日 前後</small></p>
        <b-form-text>
          ※ お客様の希望納期は3ページ目で指定できます
        </b-form-text>

      </b-form-group>


    </section>


  </section>


</template>

<script>
import {diff} from 'deep-diff'
import {mapFields, mapMultiRowFields} from 'vuex-map-fields'
import merge from "lodash/merge";

const masterState = collect([
  "print_sizes",
  "proofreading_types",
  "pdf_proofreading_types",
  "spare_types",

  "front_cover_submitted_types",
  "front_cover_paper_types",
  "front_cover_print_color_types",
  "front_cover_print_option_types",
  "front_cover_processing_types",

  "variable_data_print_types",
  "variable_data_print_place_types",

  "binding_method_types",
])

const aggregateState = collect([
  "front_cover_submitted_type_price",
  "front_cover_paper_type_price",
  "front_cover_print_color_type_price",
  "front_cover_print_option_type_price",
  "front_cover_processing_type_price",

  "variable_data_print_type_price",
  "variable_data_print_place_price",

  "binding_method_type_1_price",
  "binding_method_type_2_price",
  "binding_method_type_3_price",
  "binding_method_type_4_price",
  "binding_method_type_5_price",
  "binding_method_type_6_price",
  "binding_method_type_7_price",
  "binding_method_type_8_price",
  "binding_method_type_9_price",
  "binding_method_type_10_price",
  "binding_method_type_11_price",
  "binding_method_type_12_price",
  "binding_method_type_13_price",
  "binding_method_type_14_price",
  "binding_method_type_15_price",
  "binding_method_type_16_price",
  "binding_method_type_17_price",
  "binding_method_type_18_price",
  "binding_method_type_19_price",
  "binding_method_type_20_price",
  "binding_method_type_21_price",
  "binding_method_type_22_price",
  "binding_method_type_23_price",
  "binding_method_type_24_price",
  "binding_method_type_25_price",
  "binding_method_type_26_price",
  "binding_method_type_27_price",
  "binding_method_type_28_price",

  "body_number_of_total_pages",
  "number_of_total_pages",

  "product_sub_total_price",
  "shipping_price",
  "product_and_shipping_sub_total_price",

  "proofreading_type_number_of_times_price",
  "proofreading_type_number_of_prints_price",
  "proofreading_type_number_of_places_price",

  "spare_type_price",
  "product_and_shipping_and_proofreading_and_spare_sub_total_price",

  "tax",
  "total_price",

  "price_per_page",
  "price_per_print",
  "delivery_required_days",
])

const formState = collect([
  "number_of_prints",
  "print_size_id",

  "proofreading_type_id",
  "proofreading_type_number_of_times",
  "proofreading_type_number_of_prints",
  "proofreading_type_number_of_places",
  "pdf_proofreading_type_id",

  "spare_type_id",
  "spare_type_number_of_prints",

  "front_cover_submitted_type_id",
  "front_cover_paper_type_id",
  "front_cover_print_color_type_id",
  "front_cover_print_option_type_id",
  "front_cover_print_option_type_number_of_die_cuts",
  "front_cover_processing_type_id",

])

const formMultipleRowState = collect([
  "variable_data_print_type_inputs",
  "variable_data_print_place_inputs",
  "binding_method_type_inputs",
])

let formStateUnWatchHandlers = []
let formMultipleRowStateUnWatchHandlers = []

export default {
  computed: {
    ...mapFields("form", [
      //マスタ
      "master",
      ...masterState.map((key) => `master.${key}`).all(),

      // 入力値
      "form",
      ...formState.map((key) => `form.${key}`).all(),
      // 集計値
      "aggregate",
      ...aggregateState.map((key) => `aggregate.${key}`).all(),
    ]),
    ...mapMultiRowFields("form", [
      ...formMultipleRowState.map((key) => `form.${key}`).all()
    ]),
    isDisabledAddVariableDataPrintTypeInput() {
      return this.form.variable_data_print_type_inputs.length >=
        collect(this.variable_data_print_types)
          .reject((item) => item.id === "なし")
          .all().length
    },
    isExistenceVariableDataPrintPlaceInput() {
      return collect(this.variable_data_print_type_inputs)
        .reject((input) => input.id === "なし")
        .isNotEmpty()
    },
    isDisabledAddBindingMethodTypeInput() {
      return this.form.binding_method_type_inputs.length >=
        collect(this.binding_method_types)
          .reject((item) => item.id === "なし")
          .all().length
    },
  },
  created() {
    formStateUnWatchHandlers = formState.map((key) =>
      this.$store.watch(() => this.$store.state.form.form[key],
        (() => {
          const patternInputTextHandler = (value) => this.$emit("update-form", {[key]: value})
          const patternSingleSelectHandler = (id) => this.$emit("update-form", {[key]: {[id]: 1}})
          const patternSingleSelectAndValueHandler = (id, value) => this.$emit("update-form", {[key]: {[id]: value}})

          //イベント発火
          switch (key) {
            case "number_of_prints":
              return (newValue) => patternInputTextHandler(newValue)
            case "print_size_id":
              return (newValue) => patternSingleSelectHandler(newValue)

            case "proofreading_type_id":
              return (newValue, oldValue) => {
                if (oldValue !== null) {
                  if (['なし'].includes(newValue)) {
                    this.form.proofreading_type_number_of_times = 1
                    this.form.proofreading_type_number_of_prints = 1
                    this.form.proofreading_type_number_of_places = 1
                    this.pdf_proofreading_type_id = "なし"
                  } else {
                    this.pdf_proofreading_type_id = "あり"
                  }
                }
                patternSingleSelectHandler(newValue)
              }
            case "proofreading_type_number_of_times":
            case "proofreading_type_number_of_prints":
            case "proofreading_type_number_of_places":
              return (newValue) => patternInputTextHandler(newValue)
            case "pdf_proofreading_type_id":
              return (newValue) => patternSingleSelectHandler(newValue)
            case "spare_type_id":
              return (newValue, oldValue) => {
                if (oldValue !== null) {
                  if (['なし'].includes(newValue)) {
                    this.form.spare_type_number_of_prints = (() => {
                      if (this.form.number_of_prints <= 99) {
                        return 1
                      } else if (this.form.number_of_prints <= 499) {
                        return 2
                      } else if (this.form.number_of_prints <= 999) {
                        return 3
                      }
                      return 4
                    })()
                  }
                }
                patternSingleSelectAndValueHandler(newValue, this.form.spare_type_number_of_prints)
              }
            case "spare_type_number_of_prints":
              return (newValue) => patternSingleSelectAndValueHandler(this.form.spare_type_id, newValue)
            //
            case "front_cover_submitted_type_id":
              return (newValue, oldValue) => {
                if (oldValue !== null) {
                  if (['なし'].includes(newValue)) {
                    this.front_cover_paper_type_id = "なし"
                    this.front_cover_print_color_type_id = "なし"
                    this.front_cover_processing_type_id = "なし"
                    this.front_cover_print_option_type_id = "なし"
                    this.front_cover_print_option_type_number_of_die_cuts = 1
                  } else {
                    this.front_cover_paper_type_id = "上質 70kg"
                    this.front_cover_print_color_type_id = "4ｃ/0ｃ"
                  }
                }
                patternSingleSelectHandler(newValue)
              }
            case "front_cover_paper_type_id":
            case "front_cover_print_color_type_id":
            case "front_cover_print_option_type_id":
              return (newValue, oldValue) => {
                if (oldValue !== null) {
                  this.form.front_cover_print_option_type_number_of_die_cuts = 1
                }
                patternSingleSelectAndValueHandler(newValue, this.form.front_cover_print_option_type_number_of_die_cuts)
              }
            case "front_cover_print_option_type_number_of_die_cuts":
              return (newValue) => patternSingleSelectAndValueHandler(this.form.front_cover_print_option_type_id, newValue)
            case "front_cover_processing_type_id":
              return (newValue) => patternSingleSelectHandler(newValue)
            //
            case "body_submitted_type_id":
              return (newValue, oldValue) => {
                if (oldValue !== null) {
                  if (['PDF（推奨）', 'なし'].includes(newValue)) {
                    this.form.body_submitted_type_number_of_pages = 1
                  }
                  if (['なし'].includes(newValue)) {
                    this.body_paper_type_id = "なし"
                    this.form.body_number_of_color_pages = 0
                    this.form.body_number_of_black_and_white_pages = 0
                    this.form.body_number_of_2_color_pages = 0
                    this.form.body_number_of_white_color_pages = 0
                    this.form.body_number_of_single_sided_printing_pages = 0
                  } else {
                    this.body_paper_type_id = "上質 70kg"
                  }

                }
                patternSingleSelectAndValueHandler(newValue, this.form.body_submitted_type_number_of_pages)
              }
            default:
              return (value) => {
              }
          }
        })()
        , {deep: true})
    )
    formMultipleRowStateUnWatchHandlers = formMultipleRowState.map((key) =>
      this.$store.watch(() => this.$store.state.form.form[key],
        (() => {
          const patternMultipleSelectHandler = (rows) => this.$emit("update-form", {
            [key]: collect(rows)
              .map((row) => {
                return {[row.id]: 1}
              })
              .all()
          })
          const patternMultipleSelectAndValueHandler = (rows) => this.$emit("update-form", {
            [key]: collect(rows)
              .map((row) => {
                return {[row.id]: row.value}
              })
              .all()
          })

          //イベント発火
          switch (key) {
            case "variable_data_print_type_inputs":
              return (newValue, oldValue) => {
                if (oldValue.length > 0) {
                  if (collect(newValue)
                    .reject((item) => item.id === "なし")
                    .isEmpty()) {
                    this.form.variable_data_print_place_inputs = [
                      {id: "なし", value: 1}
                    ]
                  }
                }
                patternMultipleSelectHandler(newValue)
              }
            case "variable_data_print_place_inputs":
              return (newValue, oldValue) => {
                if (oldValue.length > 0) {
                  collect(this.form.variable_data_print_place_inputs)
                    .each((input) => {
                      if (input.id === "なし") {
                        input.value = 1
                      }
                    })
                }
                patternMultipleSelectAndValueHandler(newValue)
              }
            case "binding_method_type_inputs":
              return (newValue, oldValue) => {
                if (oldValue.length > 0) {
                  collect(this.form.binding_method_type_inputs)
                    .each((input) => {
                      if (![
                        "ミシン目加工（×本数入力+1日）",
                        "筋押し加工（×本数入力+1日）",
                        "ジャバラ折 （折の数入力）",
                        "長尺ジャバラ折 （折山の数入力）",
                        "封入り（アイテム件数で入力）",
                        "型抜き（ハーフカット・デジタル加工、タック紙）",
                        "型抜き（フルカット・デジタル加工、+2日）",
                        "金型型抜き（フルカット）斤量160kg以上+5日",
                        "金型エンボス（型代+加工、+6日）"
                      ].includes(input.id)) {
                        input.value = 1
                      }
                    })
                }
                patternMultipleSelectAndValueHandler(newValue)
              }
          }
        })()
        , {deep: true})
    )

  },
  destroyed() {
    formStateUnWatchHandlers.each(handler => handler())
    formMultipleRowStateUnWatchHandlers.each(handler => handler())
  },
  methods: {
    addVariableDataPrintTypeInput() {
      this.form.variable_data_print_type_inputs.push({id: "なし"})
      this.form.variable_data_print_place_inputs.push({id: "なし", value: 1})
    },
    removeVariableDataPrintTypeInput(index) {
      this.form.variable_data_print_type_inputs.splice(index, 1)
      this.form.variable_data_print_place_inputs.splice(index, 1)
    },
    isExistenceBindingMethodTypeValue(id) {
      return [
        "ミシン目加工（×本数入力+1日）",
        "筋押し加工（×本数入力+1日）",
        "ジャバラ折 （折の数入力）",
        "長尺ジャバラ折 （折山の数入力）",
        "封入り（アイテム件数で入力）",
        "型抜き（ハーフカット・デジタル加工、タック紙）",
        "型抜き（フルカット・デジタル加工、+2日）",
        "金型型抜き（フルカット）斤量160kg以上+5日",
        "金型エンボス（型代+加工、+6日）"
      ].includes(id)
    },
    addBindingMethodTypeInput() {
      this.form.binding_method_type_inputs.push({id: "なし", value: 1})
    },
    removeBindingMethodTypeInput(index) {
      this.form.binding_method_type_inputs.splice(index, 1)
    },
  }
}
</script>
