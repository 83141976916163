import axios from 'axios'

axios.interceptors.request.use(config => {
  // const token = document.head.querySelector('meta[name="csrf-token"]');
  // if (token) {
  //   config.headers['X-CSRF-TOKEN'] = token.content;
  // } else {
  //   console.error('CSRF token not found');
  // }

  console.log(config.headers)

  config.headers['X-Requested-With'] = 'XMLHttpRequest'
  return config
})
export default axios
