<template>
  <b-container fluid class="px-0 wrap flex-grow-1" id="form">
    <header class="px-4 py-2 position-relative bg-main text-center">
      <router-link :to="{ name: 'products' }" class="position-center-y text-white" style="left: 1rem;">
        <font-awesome-icon icon="angle-left" size="lg"/>
      </router-link>
      <h3 class="flex-grow-1 text-white font-weight-bold">
        <span class="d-block" v-if="product.id">
          {{ product.name }}のご注文
        </span>
        <span v-else>
          &#8203
        </span>
      </h3>
    </header>

    <section class="px-3">
      <form-wizard :startIndex="0" shape="circle" step-size="sm" color="#21913D" nextButtonText="次へ" backButtonText="戻る"
                   finishButtonText="注文確定"
                   @on-change="handleStepChange" @on-complete="handleStepComplete">
        <template #step="{ tab, transition, index}">
          <wizard-step
            :tab="tab"
            :transition="transition"
            :index="index">
            <template #active-step>
              <div v-if="tab.icon" class="wizard-icon">
                <font-awesome-icon :icon="tab.icon" size="lg" fixed-width/>
              </div>
            </template>
            <div v-if="tab.icon" class="wizard-icon" :key="'b'+index">
              <font-awesome-icon :icon="tab.icon" size="lg" fixed-width/>
            </div>
          </wizard-step>
        </template>
        <template #title>&#8203;</template>
        <tab-content title="1.仕様入力" icon="print">
          <p class="mb-4">
            お見積仕様を入力してください。<br>
            入力が完了したら画面下部の「次へ」ボタンを押してください。
          </p>
          <component :is="formComponent" @update-form="handleUpdateForm"/>
        </tab-content>
        <tab-content title="2.見積確認" icon="shopping-cart">
          <p class="mb-4">
            お客様の選択された仕様とお見積金額は下記の通りです。<br>
            ※入稿後、精査し機械的カウントで正式見積をメールで添付します。 その際、料金の多少の増減があります。<br>
            宜しければ画面下部の「次へ」ボタンを押してください。<br>
            修正する場合は、画面下部の「前へ」ボタンを押してください。
          </p>

          <component :is="confirmComponent"/>

          <b-card
            class="mb-3"
            header-bg-variant="main"
            header-text-variant="white"
            header="ご確認ください"
          >
            <ul>
              <li>有効期限は30日です。30日以内にご入稿下さい。</li>
              <li>時期によっては、納期のご希望にお答えできない場合があります。</li>
              <li>自動見積システムは、予告無しに、単価の改正行われる場合があります。</li>
              <li>入稿後、仕様等に誤り・変更等がある場合、再度正式見積になります。</li>
              <li>自動見積フォームで計算した各項目合計料金と総合計料金は、端数の調整等により実際の料金と多少の増減がある場合があります。</li>
            </ul>
          </b-card>

        </tab-content>
        <tab-content title="3.お客様情報" icon="address-card">
          <p class="mb-4">
            この度は、ご注文いただきありがとうございます。<br>
            ご案件名・ご依頼主様名等をご入力ください。
          </p>

          <order/>

        </tab-content>
      </form-wizard>
    </section>

  </b-container>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import {mapWaitingActions, mapWaitingGetters} from 'vue-wait'

import Product1Form from './Form/Product1Form'
import Product1Confirm from './Confirm/Product1Confirm'

import Product2Form from './Form/Product2Form'
import Product2Confirm from './Confirm/Product2Confirm'

import Product3Form from './Form/Product3Form'
import Product3Confirm from './Confirm/Product3Confirm'

import Product4Form from './Form/Product4Form'
import Product4Confirm from './Confirm/Product4Confirm'

import Product5Form from './Form/Product5Form'
import Product5Confirm from './Confirm/Product5Confirm'

import Product6Form from './Form/Product6Form'
import Product6Confirm from './Confirm/Product6Confirm'

import Product7Form from './Form/Product7Form'
import Product7Confirm from './Confirm/Product7Confirm'

import Product8Form from './Form/Product8Form'
import Product8Confirm from './Confirm/Product8Confirm'

import Product9Form from './Form/Product9Form'
import Product9Confirm from './Confirm/Product9Confirm'

import Product10Form from './Form/Product10Form'
import Product10Confirm from './Confirm/Product11Confirm'

import Product11Form from './Form/Product11Form'
import Product11Confirm from './Confirm/Product11Confirm'

import Product12Form from './Form/Product12Form'
import Product12Confirm from './Confirm/Product12Confirm'

import Product13Form from './Form/Product13Form'
import Product13Confirm from './Confirm/Product13Confirm'


import Order from './Order/Order'

import queue from 'queue'

export default {
  components: {Order},
  data() {
    return {
      isSetupCompleted: false,
      isFormInitializeRequiredWithDefaultValue: false,
      q: queue({
        concurrency: 1,
        autostart: true,
        results: []
      }),
    }
  },
  computed: {
    ...mapFields("form", [
      'master.product',
    ]),
    formComponent() {
      switch (this.product.id) {
        case 1:
          return Product1Form
        case 2:
          return Product2Form
        case 3:
          return Product3Form
        case 4:
          return Product4Form
        case 5:
          return Product5Form
        case 6:
          return Product6Form
        case 7:
          return Product7Form
        case 8:
          return Product8Form
        case 9:
          return Product9Form
        case 10:
          return Product10Form
        case 11:
          return Product11Form
        case 12:
          return Product12Form
        case 13:
          return Product13Form
      }
    },
    confirmComponent() {
      switch (this.product.id) {
        case 1:
          return Product1Confirm
        case 2:
          return Product2Confirm
        case 3:
          return Product3Confirm
        case 4:
          return Product4Confirm
        case 5:
          return Product5Confirm
        case 6:
          return Product6Confirm
        case 7:
          return Product7Confirm
        case 8:
          return Product8Confirm
        case 9:
          return Product9Confirm
        case 10:
          return Product10Confirm
        case 11:
          return Product11Confirm
        case 12:
          return Product12Confirm
        case 13:
          return Product13Confirm

      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (from.name === "products") {
        vm.isFormInitializeRequiredWithDefaultValue = true
      }
      await vm.setupFormAction({
        isFormInitializeRequiredWithDefaultValue: vm.isFormInitializeRequiredWithDefaultValue
      })
      vm.isSetupCompleted = true
    })
  },
  created() {
    this.q.on('success', async (result, job) => {
      if (this.q.length <= 0) {
        dump("reloadAggregatesAction")
        await this.reloadAggregatesAction({})
      }
      console.log('The result is:', result)
    })
  },
  mounted() {
    const unwatchIsLiveProp = this.$watch('formComponent', (newVal) => {
      if (newVal) {
        window.parent.postMessage(document.getElementById("app").offsetHeight, '*')
        unwatchIsLiveProp()
      }
    })
  },
  beforeDestroy() {
    this.q.end()
  },
  methods: {
    ...mapWaitingActions("form", [
      "setupFormAction",
      "updateServerInputAction",
      "reloadAggregatesAction",
      "submitOrderAction",
    ]),
    async handleUpdateForm(inputs) {
      if (this.isSetupCompleted) {
        this.q.push(async (cb) => {
          await this.updateServerInputAction(inputs)
          dump("handleUpdateForm")
          cb(null, {
            name: "handleUpdateForm",
            ...inputs
          })
        })
      }
    },
    handleStepChange(prevIndex, nextIndex) {
      this.$scrollTo("#form")

      switch (nextIndex) {
        case 0:
        case 1:
        case 2:
          this.$nextTick(() => window.parent.postMessage(document.getElementById("app").offsetHeight, '*'))
          break;
      }

    },
    async handleStepComplete() {
      await this.submitOrderAction({})
        .then(() => this.$router.push({name: 'done'}))

    }
  }
}
</script>

<style scoped>
.wrap ::v-deep .wizard-header {
  display: none;
}
</style>

