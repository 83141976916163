import Top from "pages/Top";
import Products from "pages/Products";
import Form from "pages/Form";
import Done from "pages/Done";

export default [
  //ログイン前
  {
    path: `/`, component: Top, name: 'top',
  },
  {
    path: `/step-form/products`, component: Products, name: 'products',
  },
  {
    path: `/step-form/form/:productId`, component: Form, name: 'form',
  },
  {
    path: `/step-form/done`, component: Done, name: 'done',
  },
]
