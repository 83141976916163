<template>
  <section>
    <!-- お客様情報の入力 -->
    <section class="mb-4">

      <header class="mb-2 app-header border-bottom border-main">
        <h4 class="h4 font-weight-bold">お客様情報の入力</h4>
      </header>
      <p class="mb-4">お客様情報を入力してください。</p>

      <h5 class="h5 app-sub-header mb-2">案件基本情報</h5>

      <b-form-group
        label-cols-md="3"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <template #label>
          <div class="d-flex align-items-center justify-content-end">
            ご案件名
            <b-badge variant="main" class="ml-1">必須</b-badge>
          </div>
        </template>

        <b-input-group>
          <b-form-input placeholder="入力ください" v-model="project_name"
                        class="col-md-6" required/>
        </b-input-group>

        <b-form-invalid-feedback :state="orderErrors.has('project_name')? false : null">
          {{ orderErrors.first('project_name') }}
        </b-form-invalid-feedback>

      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <template #label>
          <div class="d-flex align-items-center justify-content-end">
            ご依頼主名
            <b-badge variant="main" class="ml-1">必須</b-badge>
          </div>
        </template>

        <b-input-group>
          <b-form-input placeholder="入力ください" v-model="customer_name"
                        class="col-md-6" required/>
        </b-input-group>

        <b-form-invalid-feedback :state="orderErrors.has('customer_name')? false : null">
          {{ orderErrors.first('customer_name') }}
        </b-form-invalid-feedback>

      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <template #label>
          <div class="d-flex align-items-center justify-content-end">
            ご担当者名
            <b-badge variant="secondary" class="ml-1">任意</b-badge>
          </div>
        </template>

        <b-input-group>
          <b-form-input placeholder="必要に応じて入力ください" v-model="staff_name"
                        class="col-md-6"/>
        </b-input-group>

        <b-form-invalid-feedback :state="orderErrors.has('staff_name')? false : null">
          {{ orderErrors.first('staff_name') }}
        </b-form-invalid-feedback>

      </b-form-group>

      <h5 class="h5 app-sub-header mb-2">ご依頼主連絡先情報</h5>

      <b-form-group
        label-cols-md="3"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <template #label>
          <div class="d-flex align-items-center justify-content-end">
            ご依頼主先郵便番号
            <b-badge variant="main" class="ml-1">必須</b-badge>
          </div>
        </template>

        <b-input-group>
          <b-form-input placeholder="入力ください" v-model="customer_zip_code"
                        class="col-md-6" required/>
        </b-input-group>

        <b-form-invalid-feedback :state="orderErrors.has('customer_zip_code')? false : null">
          {{ orderErrors.first('customer_zip_code') }}
        </b-form-invalid-feedback>

      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <template #label>
          <div class="d-flex align-items-center justify-content-end">
            ご依頼主先都道府県
            <b-badge variant="main" class="ml-1">必須</b-badge>
          </div>
        </template>

        <b-form-select v-model="customer_prefecture_id" :options="prefectures" value-field="id" text-field="name" required
                       class="col-md-6">
          <template v-slot:first>
            <b-form-select-option :value="null" disabled>選択してください</b-form-select-option>
          </template>
        </b-form-select>

        <b-form-invalid-feedback :state="orderErrors.has('customer_prefecture_id')? false : null">
          {{ orderErrors.first('customer_prefecture_id') }}
        </b-form-invalid-feedback>

      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <template #label>
          <div class="d-flex align-items-center justify-content-end">
            ご依頼主先市区町村
            <b-badge variant="main" class="ml-1">必須</b-badge>
          </div>
        </template>

        <b-input-group>
          <b-form-input placeholder="入力ください" v-model="customer_address_1"
                        class="col-md-6" required/>
        </b-input-group>

        <b-form-invalid-feedback :state="orderErrors.has('customer_address_1')? false : null">
          {{ orderErrors.first('customer_address_1') }}
        </b-form-invalid-feedback>

      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <template #label>
          <div class="d-flex align-items-center justify-content-end">
            ご依頼主先番地
            <b-badge variant="main" class="ml-1">必須</b-badge>
          </div>
        </template>

        <b-input-group>
          <b-form-input placeholder="入力ください" v-model="customer_address_2"
                        class="col-md-6" required/>
        </b-input-group>

        <b-form-invalid-feedback :state="orderErrors.has('customer_address_2')? false : null">
          {{ orderErrors.first('customer_address_2') }}
        </b-form-invalid-feedback>

      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <template #label>
          <div class="d-flex align-items-center justify-content-end">
            ご依頼主先建物名
            <b-badge variant="secondary" class="ml-1">任意</b-badge>
          </div>
        </template>

        <b-input-group>
          <b-form-input placeholder="必要に応じて入力ください" v-model="customer_address_3"
                        class="col-md-6"/>
        </b-input-group>

        <b-form-invalid-feedback :state="orderErrors.has('customer_address_3')? false : null">
          {{ orderErrors.first('customer_address_3') }}
        </b-form-invalid-feedback>

      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <template #label>
          <div class="d-flex align-items-center justify-content-end">
            メールアドレス
            <b-badge variant="main" class="ml-1">必須</b-badge>
          </div>
        </template>

        <b-input-group>
          <b-form-input type="email" placeholder="入力ください" v-model="email"
                        class="col-md-6" required/>
        </b-input-group>

        <b-form-invalid-feedback :state="orderErrors.has('email')? false : null">
          {{ orderErrors.first('email') }}
        </b-form-invalid-feedback>

      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <template #label>
          <div class="d-flex align-items-center justify-content-end">
            メールアドレス確認
            <b-badge variant="main" class="ml-1">必須</b-badge>
          </div>
        </template>

        <b-input-group>
          <b-form-input type="email" placeholder="入力ください" v-model="email_confirmation"
                        class="col-md-6" required/>
        </b-input-group>

        <b-form-text>
          ※ 確認のため再入力してください
        </b-form-text>


        <b-form-invalid-feedback :state="orderErrors.has('email_confirmation')? false : null">
          {{ orderErrors.first('email_confirmation') }}
        </b-form-invalid-feedback>

      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <template #label>
          <div class="d-flex align-items-center justify-content-end">
            電話番号
            <b-badge variant="main" class="ml-1">必須</b-badge>
          </div>
        </template>

        <b-input-group>
          <b-form-input type="tel" placeholder="入力ください" v-model="phone_number"
                        class="col-md-6" required/>
        </b-input-group>

        <b-form-invalid-feedback :state="orderErrors.has('phone_number')? false : null">
          {{ orderErrors.first('phone_number') }}
        </b-form-invalid-feedback>

      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <template #label>
          <div class="d-flex align-items-center justify-content-end">
            FAX番号
            <b-badge variant="secondary" class="ml-1">任意</b-badge>
          </div>
        </template>

        <b-input-group>
          <b-form-input type="tel" placeholder="必要に応じて入力ください" v-model="fax_number"
                        class="col-md-6"/>
        </b-input-group>

        <b-form-invalid-feedback :state="orderErrors.has('fax_number')? false : null">
          {{ orderErrors.first('fax_number') }}
        </b-form-invalid-feedback>

      </b-form-group>

      <h5 class="h5 app-sub-header mb-2">納品基本情報</h5>

      <b-form-group
        label-cols-md="3"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <template #label>
          <div class="d-flex align-items-center justify-content-end">
            支払い方法
            <b-badge variant="main" class="ml-1">必須</b-badge>
          </div>
        </template>

        <b-form-select v-model="payment_type_id" :options="payment_types" value-field="id" text-field="name" required
                       class="col-md-6">
          <template v-slot:first>
            <b-form-select-option :value="null" disabled>選択してください</b-form-select-option>
          </template>
        </b-form-select>

        <b-form-text>
          ※ 弊社で請求書を発行後、月末締め翌月末銀行振り込み<br>
          ※ 対応クレジットカードは、以下の通りとなっております。<br>
          ・Visa（ビザ）<br>
          ・MasterCard（マスターカード）<br>
          ・JCB（ジェーシービー）<br>
          ・American Express（アメリカン･エキスプレス）<br>
          ・Diners Club（ダイナース クラブ）
        </b-form-text>

        <b-form-invalid-feedback :state="orderErrors.has('payment_type_id')? false : null">
          {{ orderErrors.first('payment_type_id') }}
        </b-form-invalid-feedback>

      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <template #label>
          <div class="d-flex align-items-center justify-content-end">
            納品希望日
            <b-badge variant="main" class="ml-1">必須</b-badge>
          </div>
        </template>

        <b-input-group>
          <b-form-input type="date" placeholder="入力ください" v-model="desired_delivery_date"
                        class="col-md-6" required/>
        </b-input-group>

        <b-form-text>
          ※入力頂いた印刷内容での納期は<br>
          校了後中 {{ delivery_required_days }} 営業日前後です
        </b-form-text>

        <b-form-invalid-feedback :state="orderErrors.has('desired_delivery_date')? false : null">
          {{ orderErrors.first('desired_delivery_date') }}
        </b-form-invalid-feedback>

      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <template #label>
          <div class="d-flex align-items-center justify-content-end">
            納品別納
            <b-badge variant="main" class="ml-1">必須</b-badge>
          </div>
        </template>

        <b-form-group>
          <b-form-radio v-model="is_enabled_delivery_address" name="is_enabled_delivery_address" :value="false" required>納品先はご依頼主先と同じ</b-form-radio>
          <b-form-radio v-model="is_enabled_delivery_address" name="is_enabled_delivery_address" :value="true" required>納品先別納</b-form-radio>
        </b-form-group>

        <b-form-invalid-feedback :state="orderErrors.has('is_enabled_delivery_address')? false : null">
          {{ orderErrors.first('is_enabled_delivery_address') }}
        </b-form-invalid-feedback>

      </b-form-group>

      <template v-if="is_enabled_delivery_address === true">

        <b-form-group
          label-cols-md="3"
          label-class="text-md-right font-weight-bold pr-3"
        >
          <template #label>
            <div class="d-flex align-items-center justify-content-end">
              納品先郵便番号
              <b-badge variant="main" class="ml-1">必須</b-badge>
            </div>
          </template>

          <b-input-group>
            <b-form-input placeholder="入力ください" v-model="delivery_zip_code"
                          class="col-md-6" :required="is_enabled_delivery_address"/>
          </b-input-group>

          <b-form-invalid-feedback :state="orderErrors.has('delivery_zip_code')? false : null">
            {{ orderErrors.first('delivery_zip_code') }}
          </b-form-invalid-feedback>

        </b-form-group>

        <b-form-group
          label-cols-md="3"
          label-class="text-md-right font-weight-bold pr-3"
        >
          <template #label>
            <div class="d-flex align-items-center justify-content-end">
              納品先都道府県
              <b-badge variant="main" class="ml-1">必須</b-badge>
            </div>
          </template>

          <b-form-select v-model="delivery_prefecture_id" :options="prefectures" value-field="id" text-field="name" :required="is_enabled_delivery_address"
                         class="col-md-6">
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>選択してください</b-form-select-option>
            </template>
          </b-form-select>

          <b-form-invalid-feedback :state="orderErrors.has('delivery_prefecture_id')? false : null">
            {{ orderErrors.first('delivery_prefecture_id') }}
          </b-form-invalid-feedback>

        </b-form-group>

        <b-form-group
          label-cols-md="3"
          label-class="text-md-right font-weight-bold pr-3"
        >
          <template #label>
            <div class="d-flex align-items-center justify-content-end">
              納品先市区町村
              <b-badge variant="main" class="ml-1">必須</b-badge>
            </div>
          </template>

          <b-input-group>
            <b-form-input placeholder="入力ください" v-model="delivery_address_1"
                          class="col-md-6" :required="is_enabled_delivery_address"/>
          </b-input-group>

          <b-form-invalid-feedback :state="orderErrors.has('delivery_address_1')? false : null">
            {{ orderErrors.first('delivery_address_1') }}
          </b-form-invalid-feedback>

        </b-form-group>

        <b-form-group
          label-cols-md="3"
          label-class="text-md-right font-weight-bold pr-3"
        >
          <template #label>
            <div class="d-flex align-items-center justify-content-end">
              納品先番地
              <b-badge variant="main" class="ml-1">必須</b-badge>
            </div>
          </template>

          <b-input-group>
            <b-form-input placeholder="入力ください" v-model="delivery_address_2"
                          class="col-md-6" :required="is_enabled_delivery_address"/>
          </b-input-group>

          <b-form-invalid-feedback :state="orderErrors.has('delivery_address_2')? false : null">
            {{ orderErrors.first('delivery_address_2') }}
          </b-form-invalid-feedback>

        </b-form-group>

        <b-form-group
          label-cols-md="3"
          label-class="text-md-right font-weight-bold pr-3"
        >
          <template #label>
            <div class="d-flex align-items-center justify-content-end">
              納品先建物名
              <b-badge variant="secondary" class="ml-1">任意</b-badge>
            </div>
          </template>

          <b-input-group>
            <b-form-input placeholder="必要に応じて入力ください" v-model="delivery_address_3"
                          class="col-md-6"/>
          </b-input-group>

          <b-form-invalid-feedback :state="orderErrors.has('delivery_address_3')? false : null">
            {{ orderErrors.first('delivery_address_3') }}
          </b-form-invalid-feedback>

        </b-form-group>

      </template>


      <h5 class="h5 app-sub-header mb-2">お客様情報に関して、その他・備考</h5>

      <b-form-group
        label-cols-md="3"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <template #label>
          <div class="d-flex align-items-center justify-content-end">
            備考
            <b-badge variant="secondary" class="ml-1">任意</b-badge>
          </div>
        </template>

        <b-input-group>
          <b-form-textarea :placeholder="'弊社宛に追加で連絡事項がある場合はご入力ください。\n・変形サイズの場合、サイズの入力\n・用紙　レザックを選択した場合の用紙色の記入\n・用紙　色上質を選択した場合の用紙色の記入\n・校正の出荷先住所を記入（2か所以上ある場合）'" v-model="note"
                           rows="8"
                        class="col-md-6"/>
        </b-input-group>

        <b-form-text>
          ※ 500文字以内でお書きください
        </b-form-text>

        <b-form-invalid-feedback :state="orderErrors.has('note')? false : null">
          {{ orderErrors.first('note') }}
        </b-form-invalid-feedback>

      </b-form-group>

    </section>

  </section>
</template>

<script>

import {mapFields} from "vuex-map-fields";

const masterState = collect([
  "prefectures",
  "payment_types",

])

const aggregateState = collect([
  "delivery_required_days",
])

const orderState = collect([
  "project_name",
  "customer_name",
  "staff_name",

  "customer_zip_code",
  "customer_prefecture_id",
  "customer_address_1",
  "customer_address_2",
  "customer_address_3",

  "email",
  "email_confirmation",
  "phone_number",
  "fax_number",

  "payment_type_id",
  "desired_delivery_date",

  "is_enabled_delivery_address",
  "delivery_zip_code",
  "delivery_prefecture_id",
  "delivery_address_1",
  "delivery_address_2",
  "delivery_address_3",

  "note",
])

let orderStateUnWatchHandlers = []

export default {
  computed: {
    ...mapFields("form", [
      ...masterState.map((key) => `master.${key}`).all(),
      ...aggregateState.map((key) => `aggregate.${key}`).all(),

      //マスタ
      "order",
      "orderErrors",
      ...orderState.map((key) => `order.${key}`).all(),
    ])
  },
  created() {
    orderStateUnWatchHandlers = orderState
      .filter((name)=>[
        "is_enabled_delivery_address"
      ].includes(name)).map((key) =>
        this.$store.watch(() => this.$store.state.form.order[key],
          (() => {

            //イベント発火
            switch (key) {
              case "is_enabled_delivery_address":
                return (newValue) => {
                  if (newValue === false) {
                    this.order.delivery_zip_code = null
                    this.order.delivery_prefecture_id = null
                    this.order.delivery_address_1 = null
                    this.order.delivery_address_2 = null
                  }


                }
            }
          })()
          , {deep: true})
      )
  }

}
</script>
