import FormBackendValidation from 'form-backend-validation'
import http from 'helpers/_http'

export default class Form extends FormBackendValidation{
  static create(data = {}) {
    return new Form().withData(data);
  }
  get(url) {
    return this.submit('get', url);
  }
}
