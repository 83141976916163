<template>
  <section>
    <!-- 印刷仕様 -->
    <section class="mb-4">


      <header class="mb-2 app-header border-bottom border-main">
        <h4 class="h4 font-weight-bold">印刷仕様</h4>
      </header>

      <b-form-group
        label-cols-md="3"
        label="部数"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ number_of_prints }}<small class="ml-1">部</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="印刷サイズ"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{
            idx(collect(print_sizes), (_) => _.firstWhere('id', print_size_id).name)
          }}</p>
      </b-form-group>

      <h5 class="h5 app-sub-header mb-2">校正の有無</h5>

      <b-form-group
        label-cols-md="3"
        label="校正の有無"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{
            idx(collect(proofreading_types), (_) => _.firstWhere('id', proofreading_type_id).name)
          }}</p>
      </b-form-group>

      <template v-if="proofreading_type_id === 'あり'">

        <b-form-group
          label-cols-md="3"
          label="校正の希望回数"
          label-class="text-md-right font-weight-bold pr-3"
        >
          <p class="form-control-plaintext">{{ proofreading_type_number_of_times }}<small class="ml-1">回</small></p>
        </b-form-group>

        <b-form-group
          label-cols-md="3"
          label="校正の希望部数"
          label-class="text-md-right font-weight-bold pr-3"
        >
          <p class="form-control-plaintext">{{ proofreading_type_number_of_prints }}<small class="ml-1">部</small></p>
        </b-form-group>

        <b-form-group
          label-cols-md="3"
          label="校正の出荷先数"
          label-class="text-md-right font-weight-bold pr-3"
        >
          <p class="form-control-plaintext">{{ proofreading_type_number_of_places }}<small class="ml-1">箇所</small></p>
        </b-form-group>

        <b-form-group
          label-cols-md="3"
          label="印刷後、その印刷物のPDF校正"
          label-class="text-md-right font-weight-bold pr-3"
        >
          <p class="form-control-plaintext">{{
              idx(collect(pdf_proofreading_types), (_) => _.firstWhere('id', pdf_proofreading_type_id).name)
            }}</p>
        </b-form-group>

      </template>

      <h5 class="h5 app-sub-header mb-2">予備の有無</h5>

      <b-form-group
        label-cols-md="3"
        label="予備の有無"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{
            idx(collect(spare_types), (_) => _.firstWhere('id', spare_type_id).name)
          }}</p>
      </b-form-group>

      <template v-if="spare_type_id === 'あり'">

        <b-form-group
          label-cols-md="3"
          label="予備の希望部数"
          label-class="text-md-right font-weight-bold pr-3"
        >
          <p class="form-control-plaintext">{{ spare_type_number_of_prints }}<small class="ml-1">部</small></p>
        </b-form-group>
      </template>
    </section>

    <!-- 印刷物仕様 -->
    <section class="mb-4">
      <header class="mb-2 app-header border-bottom border-main">
        <h4 class="h4 font-weight-bold">印刷物仕様</h4>
      </header>

      <b-form-group
        label-cols-md="3"
        label="印刷物データ入稿形式"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{
            idx(collect(front_cover_submitted_types), (_) => _.firstWhere('id', front_cover_submitted_type_id).name)
          }}</p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="印刷物用紙"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{
            idx(collect(front_cover_paper_types), (_) => _.firstWhere('id', front_cover_paper_type_id).name)
          }}</p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="印刷物印刷色"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{
            idx(collect(front_cover_print_color_types), (_) => _.firstWhere('id', front_cover_print_color_type_id).name)
          }}</p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="印刷物オプション"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{
            idx(collect(front_cover_print_option_types), (_) => _.firstWhere('id', front_cover_print_option_type_id).name)
          }}</p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="印刷物PP加工"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{
            idx(collect(front_cover_processing_types), (_) => _.firstWhere('id', front_cover_processing_type_id).name)
          }}</p>
      </b-form-group>

    </section>

    <!-- バリアブルデータ仕様 -->
    <section class="mb-4">
      <header class="mb-2 app-header border-bottom border-main">
        <h4 class="h4 font-weight-bold">バリアブルデータ仕様</h4>
      </header>

      <b-card class="mb-2" v-for="(variable_data_print_type_input,index) in variable_data_print_type_inputs"
              :key="`variable_data_print_type_inputs_${index}`">

        <b-form-group
          label-cols-md="3"
          :label="`バリアブル印刷データ作成・最適化(${index+1})`"
          label-class="text-md-right font-weight-bold pr-3"
        >
          <p class="form-control-plaintext">{{
              idx(collect(variable_data_print_types), (_) => _.firstWhere('id', variable_data_print_type_input.id).name)
            }}</p>
        </b-form-group>

        <template v-if="variable_data_print_place_inputs[index]">
          <b-form-group
            label-cols-md="3"
            :label="`バリアブル印刷箇所(${index+1})`"
            label-class="text-md-right font-weight-bold pr-3"
          >
            <p class="form-control-plaintext">{{
                idx(collect(variable_data_print_place_types), (_) => _.firstWhere('id', variable_data_print_place_inputs[index].id).name)
              }}</p>
          </b-form-group>

            <b-form-group
              v-if="variable_data_print_place_inputs[index].id !== 'なし'"
              label-cols-md="3"
              :label="`バリアブル印刷箇所数(${index+1})`"
              label-class="text-md-right font-weight-bold pr-3"
            >
              <p class="form-control-plaintext">{{ variable_data_print_place_inputs[index].value }}<small class="ml-1">箇所</small></p>
            </b-form-group>


        </template>

      </b-card>
    </section>

    <!-- 製本方法仕様 -->
    <section class="mb-4">

      <header class="mb-2 app-header border-bottom border-main">
        <h4 class="h4 font-weight-bold">製本方法仕様</h4>
      </header>

      <b-card class="mb-2" v-for="(binding_method_type_input,index) in binding_method_type_inputs"
              :key="`binding_method_type_input_${index}`">

        <b-form-group
          label-cols-md="3"
          :label="index === 0? '製本方法' : `製本方法(オプション${index})`"
          label-class="text-md-right font-weight-bold pr-3"
        >
          <p class="form-control-plaintext">{{
              idx(collect(binding_method_types), (_) => _.firstWhere('id', binding_method_type_input.id).name)
            }}</p>
        </b-form-group>

        <template v-if="isExistenceBindingMethodTypeValue(binding_method_type_input.id)">
          <b-form-group
            label-cols-md="3"
            :label="index === 0? '製本方法加工数' : `製本方法加工数(オプション${index})`"
            label-class="text-md-right font-weight-bold pr-3"
          >
              <p class="form-control-plaintext">{{ binding_method_type_input.value }}<small class="ml-1">箇所</small></p>

          </b-form-group>
        </template>

      </b-card>

    </section>

    <!-- 見積もり金額 -->
    <section class="mb-4">
      <header class="mb-2 app-header border-bottom border-main">
        <h4 class="h4 font-weight-bold">見積もり金額</h4>
      </header>

      <h5 class="h5 app-sub-header mb-2">印刷仕様</h5>

      <b-form-group
        label-cols-md="3"
        label="部数"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ number_of_prints }}<small class="ml-1">部</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="印刷サイズ"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{
            idx(collect(print_sizes), (_) => _.firstWhere('id', print_size_id).name)
          }}</p>
      </b-form-group>

      <h5 class="h5 app-sub-header mb-2">印刷物仕様</h5>

      <b-form-group
        label-cols-md="3"
        label="データ料金"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(front_cover_submitted_type_price) }}<small
          class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="用紙料金"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(front_cover_paper_type_price) }}<small
          class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="印刷料金"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(front_cover_print_color_type_price) }}<small
          class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="印刷オプション料金"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(front_cover_print_option_type_price) }}<small
          class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="PP・パウチ加工料金"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(front_cover_processing_type_price) }}<small
          class="ml-1">円</small></p>
      </b-form-group>

      <h5 class="h5 app-sub-header mb-2">バリアブルデータ仕様</h5>

      <b-form-group
        label-cols-md="3"
        label="バリアブル（ナンバリング）データ作成・最適化料金"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(variable_data_print_type_price) }}<small
          class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="バリアブル（ナンバリング）印刷料金"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(variable_data_print_place_price) }}<small
          class="ml-1">円</small></p>
      </b-form-group>

      <h5 class="h5 app-sub-header mb-2">製本方法仕様</h5>

      <template v-for="(binding_method_type,index) in binding_method_types" v-if="binding_method_type.id !== 'なし'">

        <b-form-group
          label-cols-md="3"
          :label="binding_method_type.id"
          label-class="text-md-right font-weight-bold pr-3"
          v-if="_self[`binding_method_type_${binding_method_type.no}_price`] > 0"
        >
          <p class="form-control-plaintext">
            {{ numberFormat(_self[`binding_method_type_${binding_method_type.no}_price`]) }}<small
            class="ml-1">円</small></p>
        </b-form-group>
      </template>

      <h5 class="h5 app-sub-header mb-2">合計</h5>

      <b-form-group
        label-cols-md="3"
        label="税別合計金額"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(product_sub_total_price) }}<small class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="送料"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(shipping_price) }}<small class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="送料込合計料金"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(product_and_shipping_sub_total_price) }}<small
          class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="校正回数料金"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(proofreading_type_number_of_times_price) }}<small
          class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="校正部数料金"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(proofreading_type_number_of_prints_price) }}<small
          class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="校正出荷箇所数料金"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(proofreading_type_number_of_places_price) }}<small
          class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="予備部数料金"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(spare_type_price) }}<small class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="有料校正・予備込料金"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">
          {{ numberFormat(product_and_shipping_and_proofreading_and_spare_sub_total_price) }}<small
          class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="税"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext">{{ numberFormat(tax) }}<small class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <template #label>
          税・送料込総合計
        </template>
        <p class="form-control-plaintext">{{ numberFormat(total_price) }}<small class="ml-1">円</small></p>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        label="目安納期"
        label-class="text-md-right font-weight-bold pr-3"
      >
        <p class="form-control-plaintext"><small class="mr-1">校了後中</small>{{ delivery_required_days }}<small
          class="ml-1">営業日 前後</small></p>
        <b-form-text>
          ※ お客様の希望納期は3ページ目で指定できます
        </b-form-text>

      </b-form-group>


    </section>

  </section>



</template>

<script>

import {mapFields, mapMultiRowFields} from "vuex-map-fields";

let formStateUnWatchHandlers = []
let formMultipleRowStateUnWatchHandlers = []

const masterState = collect([
  "print_sizes",
  "proofreading_types",
  "pdf_proofreading_types",
  "spare_types",

  "front_cover_submitted_types",
  "front_cover_paper_types",
  "front_cover_print_color_types",
  "front_cover_print_option_types",
  "front_cover_processing_types",

  "variable_data_print_types",
  "variable_data_print_place_types",

  "binding_method_types",
])

const aggregateState = collect([
  "front_cover_submitted_type_price",
  "front_cover_paper_type_price",
  "front_cover_print_color_type_price",
  "front_cover_print_option_type_price",
  "front_cover_processing_type_price",

  "variable_data_print_type_price",
  "variable_data_print_place_price",

  "end_paper_type_price",

  "binding_method_type_1_price",
  "binding_method_type_2_price",
  "binding_method_type_3_price",
  "binding_method_type_4_price",
  "binding_method_type_5_price",
  "binding_method_type_6_price",
  "binding_method_type_7_price",
  "binding_method_type_8_price",
  "binding_method_type_9_price",
  "binding_method_type_10_price",
  "binding_method_type_11_price",
  "binding_method_type_12_price",
  "binding_method_type_13_price",
  "binding_method_type_14_price",
  "binding_method_type_15_price",
  "binding_method_type_16_price",
  "binding_method_type_17_price",
  "binding_method_type_18_price",
  "binding_method_type_19_price",
  "binding_method_type_20_price",
  "binding_method_type_21_price",
  "binding_method_type_22_price",
  "binding_method_type_23_price",
  "binding_method_type_24_price",
  "binding_method_type_25_price",
  "binding_method_type_26_price",
  "binding_method_type_27_price",
  "binding_method_type_28_price",

  "body_number_of_total_pages",
  "number_of_total_pages",

  "product_sub_total_price",
  "shipping_price",
  "product_and_shipping_sub_total_price",

  "proofreading_type_number_of_times_price",
  "proofreading_type_number_of_prints_price",
  "proofreading_type_number_of_places_price",

  "spare_type_price",
  "product_and_shipping_and_proofreading_and_spare_sub_total_price",

  "tax",
  "total_price",

  "price_per_page",
  "price_per_print",
  "delivery_required_days",
])

const formState = collect([
  "number_of_prints",
  "print_size_id",

  "proofreading_type_id",
  "proofreading_type_number_of_times",
  "proofreading_type_number_of_prints",
  "proofreading_type_number_of_places",
  "pdf_proofreading_type_id",

  "spare_type_id",
  "spare_type_number_of_prints",

  "front_cover_submitted_type_id",
  "front_cover_paper_type_id",
  "front_cover_print_color_type_id",
  "front_cover_print_option_type_id",
  "front_cover_print_option_type_number_of_die_cuts",
  "front_cover_processing_type_id",

  "body_submitted_type_id",
  "body_submitted_type_number_of_pages",
  "body_paper_type_id",
  "body_number_of_color_pages",
  "body_number_of_black_and_white_pages",
  "body_number_of_2_color_pages",
])

const formMultipleRowState = collect([
  "variable_data_print_type_inputs",
  "variable_data_print_place_inputs",
  "binding_method_type_inputs",
])

export default {
  computed: {
    ...mapFields("form", [
      //マスタ
      "master",
      ...masterState.map((key) => `master.${key}`).all(),

      // 入力値
      "form",
      ...formState.map((key) => `form.${key}`).all(),
      // 集計値
      ...aggregateState.map((key) => `aggregate.${key}`).all(),
    ]),
    ...mapMultiRowFields("form", [
      ...formMultipleRowState.map((key) => `form.${key}`).all()
    ]),
  },
  methods:{
    isExistenceBindingMethodTypeValue(id) {
      return [
        "ミシン目加工（×本数入力+1日）",
        "筋押し加工（×本数入力+1日）",
        "ジャバラ折 （折の数入力）",
        "長尺ジャバラ折 （折山の数入力）",
        "封入り（アイテム件数で入力）",
        "型抜き（ハーフカット・デジタル加工、タック紙）",
        "型抜き（フルカット・デジタル加工、+2日）",
        "金型型抜き（フルカット）斤量160kg以上+5日",
        "金型エンボス（型代+加工、+6日）"
      ].includes(id)
    },
  }

}
</script>
