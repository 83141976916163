import Vue from 'vue'

import {library} from '@fortawesome/fontawesome-svg-core'
import {faShoppingCart, faPrint, faAddressCard, faCheckCircle,faAngleLeft} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
library.add(faShoppingCart, faPrint, faAddressCard, faCheckCircle, faAngleLeft)
Vue.component('font-awesome-icon', FontAwesomeIcon)

import VueFormWizard from 'vue-form-wizard'
Vue.use(VueFormWizard)

import { BootstrapVue} from 'bootstrap-vue'
Vue.use(BootstrapVue)

import Vuex from 'vuex'
Vue.use(Vuex)

import VueWait from 'vue-wait'
Vue.use(VueWait)

import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo,{
  force: true,
  cancelable: false,
})

